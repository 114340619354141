// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Importa HelmetProvider
import Home from './pages/Home';
import ChartPage from './pages/ChartPage';
import About from './pages/About';
import Examples from './pages/Examples';
import Navbar from './components/Navbar';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chart" element={<ChartPage />} />
          <Route path="/about" element={<About />} />
	  <Route path="/examples" element={<Examples />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
