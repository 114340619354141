// src/pages/ChartPage.js

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { createChart } from 'lightweight-charts';
import qs from 'qs';
import Select from 'react-select';
import { FiRefreshCw } from 'react-icons/fi';
import { Helmet } from 'react-helmet-async';
import './ChartPage.css';

const ChartPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('product');

  const chartContainerRef = useRef();
  const chartInstanceRef = useRef();

  // Stati per i dati
  const [klinesData, setKlinesData] = useState([]);

  // Stati per i controlli della toolbar
  const [timeframe, setTimeframe] = useState('1D');
  const [sources, setSources] = useState(null);
  const [limit, setLimit] = useState(500);
  const [chartType, setChartType] = useState('Area');

  // Stati per le sorgenti disponibili
  const [availableSources, setAvailableSources] = useState([]);

  // Stati di caricamento e errore
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Dettagli del prodotto per la presentazione
  const [productDetails, setProductDetails] = useState(null);

  // Funzione per recuperare le sorgenti disponibili per il prodotto selezionato
  useEffect(() => {
    const fetchAvailableSources = async () => {
      try {
        const response = await axios.get(`https://api.agxfeed.com/v1/products`);
        const products = response.data;

        // Trova il prodotto con il codice corrispondente
        const product = products.find((product) => product.code === code);

        if (product) {
          // Estrai le sorgenti dai dettagli del prodotto
          const sourcesSet = new Set();
          if (product.details && product.details.length > 0) {
            product.details.forEach((detail) => {
              if (detail.source && detail.source.code) {
                sourcesSet.add(detail.source.code);
              }
            });
          }

          // Converti il Set in Array
          const sourcesArray = Array.from(sourcesSet).map((code) => ({
            value: code,
            label: code,
          }));

          setAvailableSources(sourcesArray);
        } else {
          setAvailableSources([]);
        }
      } catch (error) {
        console.error('Errore nel recuperare le sorgenti disponibili:', error);
      }
    };

    fetchAvailableSources();
  }, [code]);

  // Funzione per recuperare i dati klines
  const fetchKlinesData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        product: code,
        bucket: timeframe,
        limit: limit,
      };

      if (sources && sources.length > 0) {
        params.sources = sources.map((source) => source.value);
      }

      const response = await axios.get('https://api.agxfeed.com/v1/klines', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });

      const formattedData = response.data.map((item) => ({
        time: item.sts,
        open: item.o,
        high: item.h,
        low: item.l,
        close: item.c,
      }));

      setKlinesData(formattedData);
    } catch (error) {
      console.error('Errore nel recuperare i dati klines:', error);
      setError('Errore nel caricamento dei dati.');
    } finally {
      setIsLoading(false);
    }
  };

  // Recupero iniziale dei dati
  useEffect(() => {
    if (code) {
      fetchKlinesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  // Recupera i dettagli del prodotto per la presentazione
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`https://api.agxfeed.com/v1/products`);
        const products = response.data;
        const product = products.find((p) => p.code === code);
        setProductDetails(product);
      } catch (error) {
        console.error('Errore nel recuperare i dettagli del prodotto:', error);
      }
    };

    if (code) {
      fetchProductDetails();
    }
  }, [code]);

  // Funzione per aggiungere una nuova serie basata sul tipo selezionato
  const addSeries = (chart, type) => {
    let series;
    switch (type) {
      case 'Line':
        series = chart.addLineSeries({
          color: '#2962FF',
          lineWidth: 2,
        });
        break;
      case 'Area':
        series = chart.addAreaSeries({
          topColor: 'rgba(171, 71, 188, 0.3)',
          bottomColor: 'rgba(171, 71, 188, 0.0)',
          lineColor: 'rgba(171, 71, 188, 1)',
          lineWidth: 2,
        });
        break;
      case 'Candlestick':
      default:
        series = chart.addCandlestickSeries();
        break;
    }
    return series;
  };

  // Inizializza o aggiorna il grafico
  useEffect(() => {
    if (chartContainerRef.current && klinesData.length > 0) {
      if (chartInstanceRef.current) {
        // Rimuovi la serie esistente dal grafico
        chartInstanceRef.current.chart.removeSeries(chartInstanceRef.current.series);

        // Aggiungi la nuova serie basata sul tipo selezionato
        const newSeries = addSeries(chartInstanceRef.current.chart, chartType);
        newSeries.setData(
          chartType === 'Candlestick'
            ? klinesData
            : klinesData.map((item) => ({
                time: item.time,
                value: item.close,
              }))
        );
        chartInstanceRef.current.series = newSeries;
      } else {
        // Crea un nuovo grafico con il tipo di series selezionato
        const chart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
          layout: {
            background: { type: 'solid', color: 'transparent' },
            textColor: '#000',
          },
          grid: {
            vertLines: {
              color: '#eee',
            },
            horzLines: {
              color: '#eee',
            },
          },
          timeScale: {
            rightOffset: 10,
            barSpacing: 10,
            visible: true,
            borderVisible: false,
          },
        });

        const series = addSeries(chart, chartType);
        series.setData(
          chartType === 'Candlestick'
            ? klinesData
            : klinesData.map((item) => ({
                time: item.time,
                value: item.close,
              }))
        );

        // Salva l'istanza del grafico e della serie
        chartInstanceRef.current = { chart, series };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartType, klinesData]);

  // Usa ResizeObserver per monitorare i cambiamenti delle dimensioni del contenitore
  useEffect(() => {
    if (chartContainerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect;
        if (chartInstanceRef.current) {
          chartInstanceRef.current.chart.resize(width, height);
        }
      });

      resizeObserver.observe(chartContainerRef.current);

      return () => {
        if (chartContainerRef.current) {
          resizeObserver.unobserve(chartContainerRef.current);
        }
      };
    }
  }, []);

  // Gestori degli input
  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const handleLimitChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 1000) value = 1000;
    if (value < 1) value = 1;
    setLimit(value);
  };

  const handleSourcesChange = (selectedOptions) => {
    setSources(selectedOptions);
  };

  // Gestisci il click del pulsante "Update"
  const handleUpdateClick = () => {
    fetchKlinesData();
  };

  return (
    <div className="chart-page">
      <Helmet>
        <title>
          {productDetails ? `${productDetails.name} | AGXFeed` : 'Caricamento... | AGXFeed'}
        </title>
        <meta
          name="description"
          content={
            productDetails
              ? `Visualizza il grafico e i dati storici per ${productDetails.name} su AGXFeed.`
              : 'Visualizza il grafico e i dati storici su AGXFeed.'
          }
        />
      </Helmet>

      {/* Sezione Informativa del Prodotto */}
      {productDetails && (
        <div className="product-info">
          <h2 className="product-name">{productDetails.name}</h2>
          <p className="product-category">
            {productDetails.macrocategory} &gt; {productDetails.category}
          </p>
        </div>
      )}

      <div className="content">
        {/* Toolbar */}
        <div className="toolbar-container">
          <div className="toolbar">
            <label>
              Timeframe:
              <select value={timeframe} onChange={handleTimeframeChange}>
                <option value="1D">1D</option>
                <option value="1W">1W</option>
                <option value="1M">1M</option>
                <option value="1Y">1Y</option>
              </select>
            </label>

            <div className="sources">
              <label>Sorgenti:</label>
              <Select
                isMulti
                options={availableSources}
                value={sources}
                onChange={handleSourcesChange}
                placeholder="Seleziona sorgenti (Tutte se vuoto)"
                className="sources-select"
                classNamePrefix="sources-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '38px',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    maxHeight: '80px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>

            <label>
              Limit:
              <input type="number" min="1" max="1000" value={limit} onChange={handleLimitChange} />
            </label>

            {/* Nuovo controllo per il tipo di grafico */}
            <label>
              Tipo Grafico:
              <select value={chartType} onChange={handleChartTypeChange}>
                <option value="Candlestick">Candlestick</option>
                <option value="Line">Line</option>
                <option value="Area">Area</option>
              </select>
            </label>

            <button onClick={handleUpdateClick} className="update-button">
              <FiRefreshCw /> Update
            </button>
          </div>
        </div>

        {/* Contenitore del grafico */}
        <div className="chart-container">
          {isLoading && <p>Caricamento dati...</p>}
          {error && <p className="error">{error}</p>}

          {/* Watermark personalizzato */}
          <div className="custom-watermark">AGXFeed</div>

          <div id="chart" ref={chartContainerRef}></div>
        </div>
      </div>
    </div>
  );
};

export default ChartPage;
