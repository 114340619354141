// src/pages/Home.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaChartLine } from 'react-icons/fa'; // Importa l'icona del grafico
import { FiInfo } from 'react-icons/fi'; // Mantieni l'icona per "Scopri di più"
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/HeroSection'; // Importa il componente
import './Home.css';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  // Recupera i prodotti disponibili dall'API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.agxfeed.com/v1/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Errore nel recuperare i prodotti:', error);
        setError('Errore nel recuperare i prodotti.');
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="home-container">
      {/* Impostazione del titolo e meta tag specifici per la Home */}
      <Helmet>
        <title>AGXFeed - Informazioni per un mercato più consapevole</title>
        <meta
          name="description"
          content="AGXFeed fornisce dati aggregati sui prezzi delle materie prime agricole, offrendo informazioni accessibili per un mercato più consapevole."
        />
      </Helmet>

      {/* Hero Section */}
      <HeroSection
        title="AGXFeed"
        subtitle="Informazioni accessibili per un mercato più consapevole"
      />

      {/* Messaggio di errore se presente */}
      {error && <p className="error-message">{error}</p>}

      {/* Sezione dei prodotti */}
      <div className="products-section">
        <h2>Prodotti Disponibili</h2>
        <div className="products-table-container">
          <table className="products-table">
            <thead>
              <tr>
                <th>Codice</th>
                <th>Nome</th>
                <th>Sorgenti</th>
                <th>Visualizza</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <strong>{product.code}</strong>
                  </td>
                  <td>{product.name}</td>
                  <td>
                    {product.details && product.details.length > 0
                      ? product.details
                          .map((detail) => detail.source.name)
                          .join(', ')
                      : 'N/A'}
                  </td>
                  <td>
                    <Link
                      to={`/chart?product=${product.code}`}
                      className="view-icon"
                      title={`Visualizza ${product.name}`}
                    >
                      <FaChartLine size={20} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Home;
